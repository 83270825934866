import React, { useState, useEffect } from "react";
import axios from "axios";
import Layout from "../../components/layout";
import { Link } from "gatsby";
import SEO from "../../components/seo";
import Hero from "../../components/hero";
import kidneys from "../../images/header-image-08.png";
import ModalExemptSeries from "../../components/modalExemptSeries";
import ResourceCard from "../../components/resources/resourceCard";
import References from "../../components/references";
import AnchorLink from "react-anchor-link-smooth-scroll";

const KidneysPage = () => {
	const [openVideoModal, setOpenVideoModal] = useState(false);
	const [videoDoc, setVideoDoc] = useState();
	const [, setVideoFile] = useState("");
	const [burdenDoc, setBurdenDoc] = useState();
	const [landscapeDoc, setLandscapeDoc] = useState();

	useEffect(() => {
		async function fetchData() {
			try {
				const result = await axios.get(
					"https://d2zzqzqmnbfbm9.cloudfront.net/documents?category=Disease%20Education"
				);
				if (!result.data.errors) {
					const videoData = result.data.data.filter(d => d.id === 5750)[0];
					setVideoDoc(videoData);
					setVideoFile(
						"https://akebia-file-sync.s3.amazonaws.com/" + videoData.filename__v
					);

					const burdenData = result.data.data.filter(d => d.id === 5584)[0];
					setBurdenDoc(burdenData);

					const landscapeData = result.data.data.filter(d => d.id === 5585)[0];
					setLandscapeDoc(landscapeData);
				}
			} catch (e) {
				// console.log(e);
			}
		}

		fetchData();
	}, []);

	return (
		<Layout contact>
			<SEO title="Kidney Disease" />
			<Hero background={kidneys} large={true}>
				<div className="col-sm-7">
					<h1 className="hero-heading">Kidney Disease</h1>
					<p className="hero-copy hero-copy-padded">
						In the U.S. alone, approximately 35.5 million adults (more than 1 in
						7) have chronic kidney disease (CKD), a heterogeneous condition
						characterized by a gradual, irreversible loss of kidney structure
						and function. Progression of CKD is associated with several serious
						complications, including mineral and bone disorders, anemia, and
						cardiovascular disease. As kidney function continues to decline,
						patients may develop end-stage renal disease (ESRD) and require
						dialysis or kidney transplantation.<sup>1</sup>
					</p>
				</div>
			</Hero>
			{/* Start Breadcrumbs */}
			<div className="section section-noPad">
				<div className="container breadcrumbs">
					<div className="row">
						<div className="col">
							<div className="crumb-container">
								<Link
									to="/"
								>
									Home
								</Link>
								&nbsp;&nbsp;/&nbsp;&nbsp;
								<Link
									to="/our-science/kidney-disease"
									className=""
								>
									Science
								</Link>
								&nbsp;&nbsp;/&nbsp;&nbsp;
								<Link
									to="/our-science/kidney-disease"
									className="active"
								>
									Kidney Disease
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* End Breadcrumbs */}
			<div className="section">
				<div className="container">
					<div className="row">
						<div className="col-sm-12">
							<h2 className="section-heading">What Is Anemia Due to CKD?</h2>
						</div>
						<div className="col-sm-6">
							<p>
								Anemia is a condition in which the body has a diminished
								oxygen-carrying capacity and delivery to the body’s tissues and
								organs. Both erythropoietin (EPO) and iron are essential
								components for the production of sufficient red blood cells and
								hemoglobin for adequate oxygen delivery and normal bodily
								functioning.<sup>2,3</sup>
							</p>
							<p>
								As renal function progressively declines in CKD, iron deficiency
								and limited endogenous EPO production diminish the ability of
								red blood cells to carry and deliver oxygen.
							</p>
						</div>
						<div className="col-sm-6">
							<p>
								Approximately 15% of adult CKD patients in the U.S. are thought
								to be anemic, with increasing prevalence as the disease
								progresses.<sup>4 </sup>
								Anemia due to CKD poses a heavy clinical burden, including
								hospitalizations, cardiovascular comorbidities, and mortality.
								<sup>5 </sup>
								Learn more about anemia due to CKD and its{" "}
								<AnchorLink href="#resources">
									clinical burden
								</AnchorLink> and{" "}
								<AnchorLink href="#resources">
									treatment landscape and recent scientific advancement
								</AnchorLink>
								. For even more information,{" "}
								<AnchorLink href="#resources">
									watch our video series
								</AnchorLink>
								.
							</p>
						</div>
					</div>
				</div>
			</div>
			<div className="container">
				<div className="row">
					<div className="col">
						<hr />
					</div>
				</div>
			</div>
			<div className="section">
				<div className="container">
					<div className="row">
						<div className="col-sm-12">
							<h3 className="section-heading">
								What Is Hyperphosphatemia?<sup>6</sup>
							</h3>
						</div>
						<div className="col-sm-6">

							<p>
								Among the most prominent roles of the kidneys are the excretion
								of waste products and toxins and regulation of extracellular
								volume and electrolyte concentrations. As kidney structure and
								function are gradually lost in CKD, the ability of the kidneys to
								maintain homeostasis of water, salts, and minerals becomes
								increasingly limited.
							</p>
						</div>
						<div className="col-sm-6">
							<p>
								Hyperphosphatemia is a particularly prevalent complication of
								CKD in the later stages of the disease. It is associated with
								severe clinical outcomes, including tissue calcification, bone
								diseases, and secondary hyperparathyroidism, leading to
								cardiovascular disease and increased mortality.
							</p>
						</div>
					</div>
				</div>
			</div>
			<div className="container">
				<div className="row">
					<div className="col">
						<hr />
					</div>
				</div>
			</div>
			<div id="resources" className="section">
				<div className="container">
					<div className="row">
						<div className="col">
							<h4 className="section-heading">Resources</h4>
						</div>
					</div>
					<div className="row">
						{videoDoc && (
							<ResourceCard
								callback={() => {
									setOpenVideoModal(true);
								}}
								resource={videoDoc}
							/>
						)}
						{burdenDoc && (
							<ResourceCard
								callback={() => {
									window.open(
										"https://d2zzqzqmnbfbm9.cloudfront.net/download/" +
										burdenDoc.id,
										"_blank"
									);
								}}
								resource={burdenDoc}
							/>
						)}
						{landscapeDoc && (
							<ResourceCard
								callback={() => {
									window.open(
										"https://d2zzqzqmnbfbm9.cloudfront.net/download/" +
										landscapeDoc.id,
										"_blank"
									);
								}}
								resource={landscapeDoc}
							/>
						)}
					</div>
				</div>
			</div>
			<References>
				<p>
					<strong>1.</strong> Centers for Disease Control and Prevention.{" "}
					<a
						href="https://www.cdc.gov/kidneydisease/pdf/CKD-Factsheet-H.pdf"
						target="_blank"
						rel="noreferrer"
					>
						https://www.cdc.gov/kidneydisease/pdf/CKD-Factsheet-H.pdf
					</a>
					. Accessed March 2024.
					<br />
					<strong>2.</strong> Koury MJ, et al. <i>Nat Rev Nephrol.</i>{" "}
					2015;1(7):394-410.
					<br />
					<strong>3.</strong> Goodnough LT, et al. <i>Blood.</i>{" "}
					2010;116(23):4754-4761.
					<br />
					<strong>4.</strong> Stauffer ME, et al. <i>PLoS One.</i>{" "}
					2014;9(1):e84943.
					<br />
					<strong>5.</strong> Portoles J, et al. <i>BMC Nephrology.</i>{" "}
					2013;14:2.
					<br />
					<strong>6.</strong> Goyal R, Jialal I. StatPearls [Internet]. Treasure
					Island, FL: StatPearls Publishing; 2020.
				</p>
			</References>
			{videoDoc && (
				<ModalExemptSeries
					isOpen={openVideoModal}
					onRequestClose={() => {
						setOpenVideoModal(false);
					}}
				/>
			)}
		</Layout>
	);
};

export default KidneysPage;
